.categoriesTitle{
    font-weight: bold;
}
.categoriesGroup{
    border-radius: 5px;
    background-color: rgba(154, 154, 154, 0.3);
    margin: 1vh 1vw 1vh 1vw;
    padding: 1vh 1vw 1vh 1vw;
}
.categoriesButton{
    border: none;
    text-decoration: none;
    color: light-dark(var(--mantine-color-gray-7), var(--mantine-color-dark-0));
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;text-decoration: none;
    cursor: pointer;
}
.cookieConsent{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}