.AboutWebsite_bgImg__O2VLg{
    background-image: url(/_next/static/media/background.77a54044.png);
    background-size: cover;
    background-position: center;
    height: 55vh;
    display: flex;
    justify-self: center;
    width: 100%;
}
.AboutWebsite_intro__ipRvb{
    margin: auto;
    /* background-color: rgba(105, 105, 105, 0.2); */
    text-align: center;
    padding-right: 10px;
}
.page_categoriesTitle___ToBE{
    font-weight: bold;
}
.page_categoriesGroup__IUHJw{
    border-radius: 5px;
    background-color: rgba(154, 154, 154, 0.3);
    margin: 1vh 1vw 1vh 1vw;
    padding: 1vh 1vw 1vh 1vw;
}
.page_categoriesButton__JLvsa{
    border: none;
    text-decoration: none;
    color: var(--mantine-color-gray-7);
}
[data-mantine-color-scheme='dark'] .page_categoriesButton__JLvsa{
    color: var(--mantine-color-dark-0);
}
.page_categoriesButton__JLvsa{
    font-size: var(--mantine-font-size-sm);
    font-weight: 500;text-decoration: none;
    cursor: pointer;
}
.page_cookieConsent__lalmc{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}
.CookieConsent_banner__mqXxj{
    position: "fixed";
    bottom: 0;
    left: 0;
    width: "100%";
    background-color: "#333";
    color: "#fff";
    padding: 0px 60px 0px 20px;
    display: "flex";
    justify-content: "space-between";
    align-items: "center";
    z-index: 1000;
    background-color: #3498db;
    color: white;
}
.CookieConsent_text__iyGD0{
    margin: 0;
    font-size: "14px";
}
.CookieConsent_link__PGDkJ{
    color: "#ffd700";
    text-decoration: "underline";
}
.CookieConsent_buttons__FPhcg{
    text-align: center;
    display: "flex";
    width: 100px;
}
.CookieConsent_buttons__FPhcg button{
    padding: 2px 12px 2.5px 12px;
    background-color: white;
    color: black;
    border: 1px solid #3498db;
    border-radius: 4px;
    margin: 16px 0;
    cursor: pointer;
}
.CookieConsent_accept__tCe7A{
    background-color: "#4CAF50";
    color: "#fff";
    border: "none";
    padding: 8px 12px;
    cursor: "pointer";
    border-radius: "4px";
    width: 4.5rem;
}
.CookieConsent_decline__zCHS_{
    background-color: "#f44336";
    color: "#fff";
    border: "none";
    padding: 8px 12px;
    cursor: "pointer";
    border-radius: "4px";
    width: 5rem;
}
